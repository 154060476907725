<template>
  <div class="page-not-found">
    <div class="content">
      <div class="logo" />
      <div class="texts">
        <div class="title">
          {{ $t('pageNotFound.title') }}
        </div>
        <div class="subtitle">
          {{ $t('pageNotFound.subtitle') }}
        </div>
        <router-link
          to="/home"
          class="btn-action link"
        >
          {{ $t('pageNotFound.link') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageNotFound',
};
</script>
<style lang="scss" scoped>
  $dark-bg: #1A124B;
  $lighter-bg: #33029B;

  .page-not-found {
    position: relative;
    height: 100vh;
    background: url('~@/assets/images/background/404-top.svg') top left no-repeat,
    url('~@/assets/images/background/404-left.svg') top left no-repeat,
    url('~@/assets/images/background/404-bottom.svg') bottom right no-repeat,
    linear-gradient(339.91deg, $lighter-bg -2.33%, $dark-bg 98.52%);

    .content {
      position: absolute;
      top: 10%;
      right: 25%;
      bottom: 10%;
      left: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }

    .logo {
      margin-left: 1rem;
      height: 100%;
      width: 100%;
      background: url('~@/assets/images/background/404-logo.svg') center center no-repeat;
      background-size: contain;
    }

    .texts {
      color: white;
      text-align: center;

      .title {
        margin-top: 0.2rem;
        font-weight: 800;
        font-size: 0.42rem;
        line-height: 0.52rem;
      }

      .subtitle {
        margin-top: 0.1rem;
        font-size: 0.24rem;
        line-height: 0.29rem;
      }

      .link {
        padding: 0.19rem 0.6rem;
        margin-top: 0.46rem;
        display: inline-block;
        background: $background-primary;
      }
    }
  }
</style>
